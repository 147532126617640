import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { NavigationItem } from "./NavigationItem";

export const DropdownIcon = styled.i`
  padding: 0;
  margin: auto 10px;
`;

const NavigationSubMenuWrapper = styled.li`
  @media (min-width: 768px) {
    display: none;
  }
`

function logout() {
  window.localStorage.removeItem("currentUser");
  window.location.replace("/");
}

export function NavigationSubMenu({ title, subMenuData = [], currentRole }) {
  const [isOpen, toggleOpen] = useState(false);
  const iconType = isOpen ? "fa fa-angle-up" : "fa fa-angle-down";
  const { t } = useTranslation(["translation_lang", "content"]);
  return (
    <NavigationSubMenuWrapper className="nav-item dropdown">
      <a
        className="nav-link navcol"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => toggleOpen(!isOpen)}
      >
        {title}
        <DropdownIcon className={iconType} />
        {isOpen &&
          subMenuData.map((data, key) => <NavigationItem {...data} key={key} currentRole={currentRole} />)}
        {isOpen && <NavigationItem path="" title={t("content:text.Logout", "Logout")} onClick={logout} />}
      </a>
    </NavigationSubMenuWrapper>
  );
}
