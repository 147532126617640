import React from 'react'
import { useTranslation } from 'react-i18next'
export default function NavContact() {
  const { t } = useTranslation(['translation_lang', 'content'])

  return (
    <button className="navbutton">
      <a href="tel:+966 500 000">
        <span className="text nav-pad-1">
          {t('content:text.call', 'Call')} :{' '}
          <span
            style={{
              color: '#777777',
              paddingLeft: '8px',
              fontWeight: '700',
              letterSpacing: '.5px'
            }}
          >
            +(966) 500 000
          </span>
        </span>
      </a>
      <span className="icon">
        <img
          src="./phone-icon.png"
          alt="..."
          style={{
            maxWidth: '25px',
            borderRadius: '2px'
          }} />
      </span>
    </button>
  )
}
