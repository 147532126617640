import React from 'react'
import LoginForm from '../../../Views/Login'
import Register from '../../../Views/Register'

export default function LoginPopover() {
  return (
    <>
      <div
        className="modal fade"
        style={{ paddingRight: '0' }}
        id="login"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="box">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
                padding: '10px 13px'
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <LoginForm />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        style={{ paddingRight: '0' }}
        id="register"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="box">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                zIndex: '10',
                cursor: 'pointer',
                padding: '10px 13px'
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <Register />
          </div>
        </div>
      </div>
    </>
  )
}
