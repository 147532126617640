import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { CartContext } from "../../Context/CartContext";
import { NavigationItem } from "./NavigationItem";
import { NavigationSubMenu } from "./NavigationSubMenu";
import logo from '../../assets/plexus_logo.png';
import logo_dark from "../../assets/plexus.png";


export default function Navigation({ currentUser }) {
  const { t } = useTranslation(["translation_lang", "content"]);

  const ACCOUNT_SUB_MENUS = [
    { title: t("content:text.Profile", "Profile"), path: "/myaccount/profile", role: "" },
    { title: t("content:text.courses", "Courses"), path: "/myaccount/courses", role: "" },
    { title: t("content:text.Attendance", "Attendance"), path: "/myaccount/attendance", role: "instructor" },
    { title: t("content:text.Certificates", "Certificates"), path: "/myaccount/certificates", role: "user" },
    { title: t("content:text.Orders", "Orders"), path: "/myaccount/orders", role: "user" }
  ];
  const { data } = useContext(CartContext);
  const cartCoursesCount = data.courses.length
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const currentRole = currentUser ? currentUser.user.role : []
  console.log({ currentUser, currentRole })
  return (
    <>
      <div className={`container cont-pad-1 navbar-fixed-top ${i18n.dir()}`}>
        <a href="/" className="logo" id="navpad" style={{ color: "#000000" }}>
          <span className="text-1">
            <img src={logo} className="navimg" alt="..." width="120px" height="70px" />
          </span>
          <span className="icon-1">
            <img src={logo_dark} className="navimg" alt="..."  width="120px" height="70px" />
          </span>
        </a>
        <button
          className="navbar-toggler tog-but"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          style={{ padding: "0px 0px" }}
        >
          <ul
            className="navbar-nav nav-arabic-flex"
            style={
              i18n.dir() === "ltr"
                ? {
                  padding: "0px 0px",
                  marginLeft: "auto",
                  fontSize: "13px",
                  textTransform: "uppercase",
                }
                : {
                  padding: "0px 0px",
                  marginLeft: "0",
                  fontSize: "16px",
                }
            }
          >
            <br />
            <NavigationItem path="/" title={t("content:text.home", "HOME")} />
            <NavigationItem
              path="/courses"
              title={t("content:text.courses", "COURSES")}
            />
            <NavigationItem
              path="/center"
              title={t("content:text.centers", "CENTERS")}
            />
            <NavigationItem
              path="/aboutus"
              title={t("content:text.about us", "ABOUT US")}
            />
            <NavigationItem
              path="/contact"
              title={t("content:text.contact", "CONTACT")}
            />
            {currentUser && (
              <NavigationSubMenu
                title={t("content:text.My Account", "My Account")}
                currentRole={currentRole}
                subMenuData={ACCOUNT_SUB_MENUS}
              />
            )}
            <li className="nav-item">
              <Link
                to="/cart"
                className="nav-link navcol"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("content:text.cart", "CART")}{" "}
                <span
                  className="badge badge-danger"
                  style={{ marginLeft: "10px", padding: "5px" }}
                >
                  {" "}
                  {cartCoursesCount}{" "}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
