import React, { useContext } from 'react'
import styled from 'styled-components'
import Error from './FormError'
import { useTranslation } from "react-i18next";
import { ApiContext } from '../../Context/ApiContext';

const UploadInputLabel = styled.div`
  width: 70%;
  margin: auto;
  font-weight: 300;
  font-size: 0.9rem;
  @media (min-width: 1400px) {
    width: 60%;
    font-size: 0.8rem;
  }
`

const UploadInput = ({ error, onChange, ...props }) => {
  const { language } = useContext(ApiContext)
  const { t } = useTranslation(["translation_lang", "content"]);

  const flexDirection = language === "ar" ? { flexDirection: "row-reverse" } : { flexDirection: "row" }

  return (
    <div className="form-group d-flex" style={flexDirection}>
      <UploadInputLabel>
        {t("content:text.Upload Document", "Upload Document")} <br />
        ({t("content:text.Max 5 Files", "Max 5 Files")})
      </UploadInputLabel>
      <div>
        <input
          type="file"
          multiple
          className="form-control focusedInput"
          onChange={onChange}
          {...props}
        />
        <Error error={error} />
      </div>
    </div>
  )
}

export default UploadInput
