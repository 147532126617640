import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./Components/Layout";
// import { MyLoader } from "./Components/MyLoader";
import { PageNotFound } from "./Components/PageNotFound";
import "font-awesome/css/font-awesome.min.css";
import "./index.css";
import Layout2 from "./Components/Layout2";
import { CartProvider } from "./Context/CartContext";
import AccountLayout from "./Components/AccountLayout";

const Home = React.lazy(() => import("./Views/Home"));
const Profile = React.lazy(() => import("./Views/Accounts/Profile"));
const Orders = React.lazy(() => import("./Views/Accounts/Orders"));
const Address = React.lazy(() => import("./Views/Accounts/Address"));
const Certificates = React.lazy(() => import("./Views/Accounts/Certificates"));
const Attendance = React.lazy(() => import("./Views/Accounts/Attendance"));
const MyCourses = React.lazy(() => import("./Views/Accounts/MyCourses"));
const ForgotPassword = React.lazy(() => import("./Views/ForgotPassword"));
const Courses = React.lazy(() => import("./Views/Courses"));
const SearchCourse = React.lazy(() => import("./Views/SearchCourse"));
const Institute = React.lazy(() => import("./Views/Institute"));
const Instructor = React.lazy(() => import("./Views/Instructor"));
const Aboutus = React.lazy(() => import("./Views/Aboutus"));
const Contact = React.lazy(() => import("./Views/Contact"));
const Center = React.lazy(() => import("./Views/Center"));
const Cart = React.lazy(() => import("./Views/Cart"));
const Checkout = React.lazy(() => import("./Views/Checkout"));
const Shop = React.lazy(() => import("./Views/Shop"));
const Center_single = React.lazy(() => import("./Views/Center_single"));
const Courses_single = React.lazy(() => import("./Views/Courses_single"));
const Courses_single_home = React.lazy(() =>
  import("./Views/Courses_single_home")
);

const Instructor_single = React.lazy(() => import("./Views/Instructor_single"));


const WaitingComponent = (Component) => {
  return (props) => (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

const AccountWaitingComponent = (Component) => {
  return (props) => (
    <div>
    <Layout onlyMobileNav={true} >
      <AccountLayout>
        <Component {...props} />
      </AccountLayout>
    </Layout>
    </div>
  );
};


const WaitingComponentWithoutLayout = (Component) => {
  return (props) => (
    <CartProvider>
      <Component {...props} />
    </CartProvider>
  );
};

const Layout2WaitingComponent = (Component) => {
  return (props) => (
    <Layout2>
      <Component {...props} />
    </Layout2>
  );
};

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/" component={WaitingComponent(Home)} />
        <Route
          path="/myaccount"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/profile`} component={AccountWaitingComponent(Profile)} />
              <Route path={`${url}/courses`} component={AccountWaitingComponent(MyCourses)} />
              <Route path={`${url}/certificates`} component={AccountWaitingComponent(Certificates)} />
              <Route path={`${url}/orders`} component={AccountWaitingComponent(Orders)} />
              <Route path={`${url}/attendance`} component={AccountWaitingComponent(Attendance)} />
            </>
          )}
        />
        <Route
          path="/forgotpassword"
          component={Layout2WaitingComponent(ForgotPassword)}
        />
        <Route path="/institute" component={Layout2WaitingComponent(Institute)} />
        <Route path="/instructor" component={Layout2WaitingComponent(Instructor)} />
        <Route path="/courses" component={WaitingComponent(Courses)} />
        <Route path="/searchcourse" component={WaitingComponent(SearchCourse)} />
        <Route path="/aboutus" component={WaitingComponent(Aboutus)} />
        <Route path="/contact" component={WaitingComponent(Contact)} />
        <Route path="/shop" component={WaitingComponent(Shop)} />
        <Route path="/center" component={WaitingComponent(Center)} />
        <Route path="/cart" component={WaitingComponent(Cart)} />
        <Route path="/checkout" component={WaitingComponentWithoutLayout(Checkout)} />
        <Route
          path="/center_single/:id"
          component={WaitingComponent(Center_single)}
        />
        <Route
          path="/course/:id"
          component={WaitingComponent(Courses_single)}
        />
        <Route
          path="/instructor_all/:id"
          component={WaitingComponent(Instructor_single)}
        />

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>

    </BrowserRouter>
  );
}

export default Routes;
