import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from "react-i18next";
import CheckboxIcon from './CheckboxIcon';

const RememberMeWrapper = styled.label`
  margin-bottom: 0;
  margin-top: 5px;
  display: inline-block;
  ${({ fullPage, smShow }) =>
    fullPage && smShow &&
    css`
      @media (max-width: 1199px) and (min-width: 768px) {
        display: 'none';
      }
    `}
  @media (max-width: 450px) {
    display: ${(props) => (props.smShow ? 'inline-block' : 'none')};
  }
`

const RememberMe = ({ check, handleCheckBox, smShow, fullPage }) => {
  const { t } = useTranslation(["translation_lang", "content"]);
  return (
    <RememberMeWrapper
      htmlFor="rem"
      className="fw-normal font-lato"
      smShow={smShow}
      fullPage={fullPage}
    >
      <CheckboxIcon isChecked={check|| false} onClick={handleCheckBox}/>
      <span className="fake-label">{t("content:text.Remember Me", "Remember Me")}</span>
    </RememberMeWrapper>
  )
}

export default RememberMe
