import React from "react";
import { Link } from "react-router-dom";

export function NavigationItem({ path, title, onClick , role, currentRole = []}) {
  console.log({role, currentRole})
  const isVisible = role && currentRole.length ? currentRole.includes(role) : true
  if(!isVisible) return null
  return (
    <li className="nav-item dropdown">
      <Link
        to={path}
        className="nav-link navcol"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={onClick}
      >
        {title}
      </Link>
    </li>
  );
}
