import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import useProfile from "./useProfile";
import useCourseOrders from './useCourseOrders';
import { fetchAPI, API } from "../api";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const AccountContext = React.createContext();

const user = JSON.parse(localStorage.getItem("currentUser"));
const token = user && user.token;

export const AccountProvider = (props) => {
  if (!props.token) {
    Redirect("/")
  }
  const { userProfile, loading: profileLoading, error } = useProfile(props.token);
  const { courseOrders, loading: courseLoading } = useCourseOrders(props.token)
  const loading = profileLoading || courseLoading
  const completedCourses = courseOrders.filter(datum => datum.completion_date)
  const enrolledCourses = courseOrders.filter(datum => !datum.completion_date)
  const certificates = courseOrders.filter(datum => datum.certificate)

  return (
    <AccountContext.Provider
      value={{
        loading,
        profileLoading,
        courseLoading,
        error,
        profile: userProfile,
        completedCourses,
        enrolledCourses,
        certificates,
        orders: courseOrders,
        updateProfile: async (FormData) => {
          let result = await fetchAPI(API.updateProfile, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            method: "post",
            body: JSON.stringify(FormData),
          });

          if (result.status === "Success") {
            Toast.fire({
              icon: "success",
              title: "Profile Updated",
            });
            window.location.reload();
          } else {
            Toast.fire({
              icon: "warning",
              title: result.message,
            });
          }
        },
        changePassword: async (FormData) => {
          let result = await fetchAPI(API.changePassword, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            method: "post",
            body: JSON.stringify(FormData),
          });
          if (result.status === "Success") {
            Toast.fire({
              icon: "success",
              title: "Password Updated",
            });
            window.location.reload();
          } else {
            Toast.fire({
              icon: "warning",
              title: result.message,
            });
          }
        },
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};