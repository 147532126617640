import { useState, useEffect } from 'react';
import { fetchAPI, API } from '../api';

export default function useCourseOrders(token) {
  const [courseOrders, setCourseOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!token) return;
    setLoading(true);
    fetchAPI(API.myCourses, {
      token
    })
      .then(({ status, data }) => {
        console.log(data);
        if (status === 'Success') {
          setLoading(false);
          setCourseOrders(data);
          return () => courseOrders([]);
        }
      })
      .catch((error) => setError(error));
  }, [token]);
  return {
    courseOrders,
    loading,
    error
  };
}
