import { useState } from 'react';
import { ApiContext } from '../Context/ApiContext';
import { useContext } from 'react';
import validateRegister from "../helpers/validateRegister"

const HandleRegisterForm = () => {

    const [values, setValues] = useState({ first_name: '', last_name: '', phone: 0, email: '', password: '' });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");

    const { register, language } = useContext(ApiContext)

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        console.log(values + language)
        let { errors, cond } = validateRegister(values, confirmPassword, language)
        setErrors(errors);
        if (cond) {
            setIsSubmitting(true)
            register(values).then(() => setIsSubmitting(false))
        }
    };

    const handleConfirmPassword = (event) => {
        event.persist();
        setConfirmPassword(event.target.value);
    };

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    };

    return {
        handleChange,
        handleSubmit,
        handleConfirmPassword,
        isSubmitting,
        confirmPassword,
        values,
        errors,
    }
};

export default HandleRegisterForm;