import Shop1 from "../shop_1.jpg"
import Instructor1 from "../images/instructor1.jpg";
import Instructor2 from "../images/instructor2.jpg";
import Instructor3 from "../images/instructor3.jpg";
import Instructor4 from "../images/instructor4.jpg";
import Course1 from "../images/course1.jpg";
import Course2 from "../images/course2.jpg";
import Course3 from "../images/course3.jpg";
import Course4 from "../images/course4.jpg"; 

const CoursesData = [
    {
        name: "Airline Transport Pilot (ATP) Ground School",
        name_arb: "مدرسة طيار النقل الجوي (ATP) الأرضية",
        description: "NEXUS Academy ground school provides pilots with the required knowledge and skill to pass the ATP knowledge test; it is designed in accordance with the requirements of the relevant competent authority.",
        requirements: "To be eligible to take FAA's ATP practical test, the candidate must have at least 1500 hours of experience in aircraft, including 250 hours as pilot in command (PIC) and be at least 23 years old.",
        topic: "Provides pilots with skills and knowledge required to pass the ATP theoretical test",
        institute: "5f19d6ee1a4bcc0017595e143",
        price: 199,
        location: "Institute",
        instructor_name: "Keny White",
        instructor_name_arb: "كيني وايت",
        banner: Shop1,
        instructor_img: Instructor1,
        course_img: Course1,
        thumbnail: "",
        frequency: "daily",
        frequency_arb: "",
        lectures: 6,
        end_date: "2022-06-24T04:43:30.070Z",
        start_date: "2022-06-22T04:43:30.070Z",
        online: false,
        instructor: {
            _id: "60f1987f6afc547705e73411",
            email: "instructor@yopmail.com", 
            phone: "9661234567", 
            name: "Keny White", 
            profile: {
                pictute: Instructor1
            }, 
            info: {
                biography: {
                    value: "Professor"
                },
                highlights: {
                    value: "Best Instructor in Airline Transport"
                }
            }
        },
        daily_time: 2,
        sections: [
            {
                _id: "60f1997c6afc537705e83400",
                title: "Welcome to the Course!",
                title_arb: "",
                lectures: [{
                    title: "Introduction to Airlines Transport",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83401",
                title: "Basic requirements",
                title_arb: "",
                lectures: [{
                    title: "Starting from Basics",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83402",
                title: "Course Layout",
                title_arb: "",
                lectures: [{
                    title: "Steps to follow",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            }
        ],
        completion_award: "Certificate of Completion",
        _id: "60962d4384d63b57529ff0d8",
        createdAt: "2022-06-19T04:43:30.070Z",
        category: "103",
        duration: {
            count: 3, 
            unit: "days"
        },
        updatedAt: "2022-06-19T04:43:30.070Z"
    },
    {
        name: "Pilot Training Program",
        name_arb: "برنامج تدريب الطيارين",
        description:"NEXUS Academy offers flight training at various locations around the world. Our flight training programs include the Private Pilot License (PPL), Commercial Pilot License (CPL), Instrument Rating (IR), and Certified Flight Instructor (CFI and Certified Flight Instruments Instructor (CFII), Multi-engine Instructor (MEI). Contact us to learn more about how you can become a pilot. China program details and duration: 2 month Pre-Departure Training in China, 12 months Training for PPL, IR, CPL, 6-8 months training for CFI, CFII, MEI, Options for a job in the U.S. for those who qualify for 12 months.",
        requirements:"The requirements are to be at least 16 for the Private Pilot License (PPL) and at least 18 for Commercial Pilot License (CPL). Has successfully completed high school and have an Operational ICAO ELP Level 4.",
        topic:"Various locations that provide prospective pilots with the skills and knowledge required to obtain their Licenses.",
        institute: "5f19d6ee1a4bcc0017595e1412",
        price: 299,
        location: "Institute",
        instructor_name: "Steve Johnson",
        instructor_name_arb: "سارة جونسون",
        banner: Shop1,
        instructor_img: Instructor2,
        course_img: Course2,
        thumbnail: "",
        frequency: "daily",
        frequency_arb: "",
        lectures: 10,
        end_date: "2022-06-26T04:43:30.070Z",
        start_date: "2022-06-22T04:43:30.070Z",
        online: false,
        instructor: {
            _id: "60f1987f6afc547705e73422",
            email: "instructor@yopmail.com", 
            phone: "9661234567", 
            name: "Steve Johnson", 
            profile: {
                pictute: Instructor2
            }, 
            info: {
                biography: {
                    value: "Professor"
                },
                highlights: {
                    value: "Best Instructor in Pilot training"
                }
            }
        },
        daily_time: 2,
        sections: [
            {
                _id: "60f1997c6afc537705e83410",
                title: "Welcome to the Course!",
                title_arb: "",
                lectures: [{
                    title: "Introduction to Pilot Training",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83411",
                title: "Basic requirements",
                title_arb: "",
                lectures: [{
                    title: "Start from Fundamentals",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83412",
                title: "Course Layout",
                title_arb: "",
                lectures: [{
                    title: "Steps to follow",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            }
        ],
        completion_award: "Certificate of Completion",
        _id: "609616f284d63b57529ff0c6",
        createdAt: "2022-06-19T04:43:30.070Z",
        category: "102",
        duration: {
            count: 5, 
            unit: "days"
        },
        updatedAt: "2022-06-19T04:43:30.070Z"
    },
    {
        name: "Customer Service Training",
        name_arb:"تدريب خدمة العملاء",
        description:"To develop customer service skills and apply the recommended techniques as best practices. This course prepares students with operationally relevant, work-related service skills to successfully function in a complex and dynamic aviation environment in which a good customer service with external and internal customers is vital. Its effectiveness is based on proven classroom materials and approaches adopted by qualified and experienced instructors.",
        requirements:"Preferably for Aviation Companies ICAO English Language Proficiency Level 3, for service companies English Language Level 3-4",
        topic:"The Customer Service Training Course is designed for the frontline staff who represent the first impression of the organizations. This course has been developed to assist customer services representatives to enhance their skills and understand the importance of their job as well as the costs and consequences of not providing a good service.",
        institute: "5f19d6ee1a4bcc0017595e144",
        price: 159,
        location: "Institute",
        instructor_name: "John Milton",
        instructor_name_arb: "جون ميلتون",
        banner: Shop1,
        instructor_img: Instructor3,
        course_img: Course3,
        thumbnail: "",
        frequency: "daily",
        frequency_arb: "",
        lectures: 2,
        end_date: "2022-06-25T04:43:30.070Z",
        start_date: "2022-06-22T04:43:30.070Z",
        online: false,
        instructor: {
            _id: "60f1987f6afc547705e73433",
            email: "instructor@yopmail.com", 
            phone: "9661234567", 
            name: "John Milton", 
            profile: {
                pictute: Instructor3
            }, 
            info: {
                biography: {
                    value: "Professor"
                },
                highlights: {
                    value: "Best Instructor in Customer Service Training"
                }
            }
        },
        daily_time: 8,
        sections: [
            {
                _id: "60f1997c6afc537705e83420",
                title: "Welcome to the Course!",
                title_arb: "",
                lectures: [{
                    title: "Introduction to Customer Service",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83421",
                title: "Basic requirements",
                title_arb: "",
                lectures: [{
                    title: "Starting from Basics",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83422",
                title: "Course Layout",
                title_arb: "",
                lectures: [{
                    title: "Steps to follow",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            }
        ],
        completion_award: "Certificate of Completion",
        _id: "60962e3884d63b57529ff0d9",
        createdAt: "2022-06-19T04:43:30.070Z",
        category: "104",
        duration: {
            count: 4, 
            unit: "days"
        },
        updatedAt: "2022-06-19T04:43:30.070Z"
    },
    {
        name: "Quality Management Systems (QMS) in Aviation",
        name_arb:"نظم إدارة الجودة (QMS) في الطيران",
        description:"This course introduces you to Quality Management Systems (QMS) mechanisms and goals that you can implement and maintain within your operations. Understand the key quality requirements of significant regulatory bodies such as ISO, EASA, and FAA.",
        requirements:"ICAO English Language Proficiency Level 3.",
        topic:"Provides participants the outline basis of effective Quality Management Systems (QMS)",
        institute: "5f19dc451a4bcc0017595e152",
        price: 268,
        location: "center",
        instructor_name: "Peter Parker",
        instructor_name_arb: "بيتر باركر",
        banner: Shop1,
        instructor_img: Instructor4,
        course_img: Course4,
        thumbnail: "",
        frequency: "daily",
        frequency_arb: "",
        lectures: 10,
        end_date: "2022-06-26T04:43:30.070Z",
        start_date: "2022-06-22T04:43:30.070Z",
        online: false,
        instructor: {
            _id: "60f1987f6afc547705e73444",
            email: "instructor@yopmail.com", 
            phone: "9661234567", 
            name: "Peter Parker", 
            profile: {
                pictute: Instructor4
            }, 
            info: {
                biography: {
                    value: "Professor"
                },
                highlights: {
                    value: "Best Instructor in Quality Management Systems"
                }
            }
        },
        daily_time: 2,
        sections: [
            {
                _id: "60f1997c6afc537705e83430",
                title: "Welcome to the Course!",
                title_arb: "",
                lectures: [{
                    title: "Introduction to Quality Management Systems",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83431",
                title: "Basic requirements",
                title_arb: "",
                lectures: [{
                    title: "Start from Basics",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            },
            {
                _id: "60f1997c6afc537705e83432",
                title: "Course Layout",
                title_arb: "",
                lectures: [{
                    title: "Course Layout",
                    title_arb: "",
                    start_time: "2022-06-21T04:43:30.070Z",
                    end_time: "2022-06-19T07:43:30.070Z"
                }]
            }
        ],
        completion_award: "Certificate of Completion",
        _id: "6096302484d63b57529ff0da",
        createdAt: "2022-06-19T04:43:30.070Z",
        category: "101",
        duration: {
            count: 5, 
            unit: "days"
        },
        updatedAt: "2022-06-19T04:43:30.070Z"
    }
]

export default CoursesData;