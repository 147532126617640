import React, { useContext, useEffect } from "react";
import "../Views/Courses.css";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import { ApiContext } from "../Context/ApiContext";
import { useTranslation } from "react-i18next";

let fallback = require('../assets/fallback.jpg')
let user_fallback = require('../assets/user_fallback.svg')

export const PopularCourses = (props) => {
    const id = props.course._id;
    const course = props.course
    // const name = course.name.replace(/[ ,:]/g, '-');
    const { language } = useContext(ApiContext)
    const flexDirection = language === "ar" ? { flexDirection: "row-reverse" } : { flexDirection: "row" }
    const { t } = useTranslation(["translation_lang", "content"]);

    return (
        <Link to={{pathname: '/course/'+course.name, state : {id}}}>
            <div key={course._id} className={`row ${i18n.dir()}`}>
                <div className="col-md" style={{ paddingRight: "0" }}>
                    <a href="./courses">
                        <img
                            style={{
                                height: "60px",
                                width: "60px",
                                marginBottom: "10px",
                            }}
                            src={course.course_img}
                            className="img2"
                            alt="..."
                        ></img>
                    </a>
                </div>

                <div className="col-md-8">
                    <h5 style={{
                        fontSize: "14px", color: "#777777", lineHeight: "18px",
                        textOverflow: "ellipsis",
                        // "-webkit-line-clamp": "2",
                        WebkitLineClamp: "2",
                        overflow: "hidden",
                        display: "-webkit-box",
                        // "-webkit-box-orient": "vertical",
                        WebkitBoxOrient: "vertical",
                    }}>
                        {course.name}
                    </h5>
                    {course.price === 0 ? (
                        <p style={{ color: "green", fontSize: "14px" }}>
                            {t("content:text.Free", "Free")}
                        </p>
                    ) : (
                            <p style={{ color: "#32BACD", fontSize: "14px" }}>
                                {t("content:text.SAR", "SAR")}
                                {course.price}
                            </p>
                        )}
                </div>
            </div>
        </Link>
    );
};
