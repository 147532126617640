import React from "react";

export const ThemeContext = React.createContext();


export const ThemeProvider = props => {

    const palette = {
        purple: '#5A31F4',
        green: '#0ECD9D',
        red: '#CD0E61',
        black: '#0B0B0B',
        white: '#F0F2F3',
    }

    const theme = {
        colors: {
            background: palette.white,
            foreground: palette.black,
            primary: palette.purple,
            success: palette.green,
            danger: palette.red,
            failure: palette.red,
        },
        spacing: {
            s: 8,
            m: 16,
            l: 24,
            xl: 40,
        },
        textVariants: {
            xl: {
                fontSize: "44px",
            },
            l: {
                fontSize: "30px",
            },
            m: {
                fontSize: "18px",
            },
            m1: {
                fontSize: "14px",
            },
            m2: {
                fontSize: "16px",
            },
            s: {
                fontSize: "12px",
            },
            xs: {
                fontSize: "8px",
            }
        },
        textVariantsArb: {
            xl: {
                fontSize: "48px",
            },
            l: {
                fontSize: "34px",
            },
            m: {
                fontSize: "22px",
            },
            m1: {
                fontSize: "18px",
            },
            m2: {
                fontSize: "20px",
            },
            s: {
                fontSize: "16px",
            },
            xs: {
                fontSize: "12px",
            }
        }
    };

    const darkTheme = {
        ...theme,
        colors: {
            ...theme.colors,
            background: palette.black,
            foreground: palette.white,
        }
    }

    return (
        <ThemeContext.Provider
            value={{
                theme
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};
