/**
 * !Desc: This module is for the Navbars
 * * Create two top navbars to be added for Maher Learning App.
 *
 * *Developed by : Deepan
 * *Contributors : Saran, Karthik
 * *Date : July 27th 2020
 * *Version - Date - Developer Name - Change Description (if any)
 * * Draft - 07/25/2020 - Deepan - Created Draft
 * * v1.0 - 07/27/2020 - Saran - Added Arabic changes (imported langDir.fDir)
 *
 * ? Requirements for this module
 * * This is to serve the home page of the MAHER App
 * * Asscoiated Styling sheet : Navbar.css
 *
 *
 * ! Add addtional lines here whenever you make a change to this file.
 * ! Refactoring should be explained clearly
 */

import React, { useState, useEffect } from "react";
import "./index.css";
import i18n from "../../i18n";
import langDir from "../LangDir";
import Navigation from "./Navigation";
import Ribbon from "./Ribbon";
import styled from "styled-components";

const NavbarWrapper = styled.div`
  background-color: #222222;
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${({ onlyMobileNav }) =>
    onlyMobileNav &&
    `
      & .navbar-trans {
        max-width: 100vw;
      }
  `};
  @media (min-width: 768px) {
    ${({ onlyMobileNav }) =>
      onlyMobileNav &&
      `
      & .navbar-trans {

        display: none
      }
  `}
  }
`;
const CustomNavigation = ({ isTop, isVisible, currentUser }) => {
  if (!isVisible) return null;
  const className = isTop ? "navbar-light nav-1" : "navbar-dark nav-2";

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top navbar-inverse navbar-trans ${className}`}
    >
      <Navigation currentUser={currentUser}/>
    </nav>
  );
};

function Navbar({
  showRibbon = true,
  showNavigation = true,
  onlyMobileNav = false,
}) {
  const [isTop, setIsTop] = useState(true);

  const userData = localStorage.getItem("currentUser");
  const currentUser = JSON.parse(userData);

  // const history = useHistory();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("Language"));

    document.addEventListener("scroll", () => {
      const TopCheck = window.scrollY < 47;
      if (TopCheck !== isTop) {
        setIsTop(TopCheck);
        // eslint-disable-next-line eqeqeq
      }
    });
  }, [isTop]);

  return (
    <NavbarWrapper flexDirection={langDir.fDir} onlyMobileNav={onlyMobileNav}>
      <div className="container cont-pad">
        {showRibbon && <Ribbon currentUser={currentUser} />}
        <CustomNavigation
          isTop={isTop}
          isVisible={showNavigation}
          currentUser={currentUser}
        />
      </div>
    </NavbarWrapper>
  );
}

export default Navbar;
