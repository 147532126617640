import React from "react";
import Navbar2 from "./Navbar2";
import Footer from "./Footer";

export default function Layout2(props) {
    return (
        <div>
            <div style={{ position: "absolute", top: "0", width: "100%" }}>
                <Navbar2 />
            </div>
            <div style={{ minHeight: "50%" }}>{props.children}</div>
            <div style={{ position: "relative", bottom: "0", width: "100%" }}>
                <Footer />
            </div>
        </div>
    );
}
