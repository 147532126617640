import React, { useEffect, useContext } from "react";
import "./Footer.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
// import data from "../assets/database/popular.json";
import { Link } from "react-router-dom";
import { ApiContext } from "../Context/ApiContext";
import { PopularCourses } from "../Components/PopularCourses"
import CoursesData from "../assets/staticdata/courses";
import logo from "../assets/plexus_logo.png";

function Footer() {
  const { t } = useTranslation(["translation_lang", "content"]);

  // const { popularCourse } = useContext(ApiContext)

  // const courses = data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="footer" style={{ fontFamily: "Lato" }}>
        <div className="container" style={{ maxWidth: "100%" }}>
          <footer
            className="page-footer font-small mdb-color pt-4"
            style={{ color: "#ffffff", fontWeight: "300" }}
          >
            <div className="container text-md-left">
              <div className={`row ${i18n.dir()}`}>
                <div className="col-md-3" style={{ lineHeight: "15px" }}>
                  <a href="/" className="logo" style={{ color: "#4dca81" }}>
                    <img
                      src={logo}
                      style={{ maxWidth: "200px" }}
                      alt="..."
                      width="140px" 
                      height="80px"
                    />
                  </a>
                  <p
                    style={{
                      paddingTop: "1em",
                      fontSize: "12px",
                      lineHeight: "21px",
                      fontWeight: "400",
                      color: "#777777",
                    }}
                  >
                    {t(
                      "content:text.Footer Desc",
                      "We have over 20 years experience providing expert Educational both businesses and individuals. Our Investment Committee brings cades the industry expertise in driving our investment approach. portfolio constructor and allocation"
                    )}{" "}
                  </p>
                  <button
                    className="btn btn-outline-light"
                    style={{
                      borderRadius: "0px",
                      borderColor: "#777777",
                      fontSize: "12px",
                      lineHeight: "21px",
                      fontWeight: "700",
                      borderWidth: "2px",
                    }}
                  >
                    {" "}
                    <Link to="/courses">
                      <div style={{ color: "#777777" }}>
                        {t(
                          "content:text.Start learning now",
                          "START LEARNING NOW"
                        )}{" "}
                      </div>
                    </Link>
                  </button>
                </div>

                <hr className="w-100 clearfix d-md-none" />

                <div className="col-md-3 dis-no" style={{ lineHeight: "15px" }}>
                  <h6
                    className="text-uppercase mb-4"
                    style={{
                      fontSize: "14px",
                      color: "#777777",
                      fontWeight: "700",
                    }}
                  >
                    {t("content:text.Popular Courses", "POPULAR COURSES")}
                  </h6>
                  <hr
                    style={{
                      height: "1px",
                      border: "none",
                      backgroundColor: "#3A3A3A",
                      color: "#3A3A3A",
                    }}
                  />
                  {
                    CoursesData.length !== 0 ? [...CoursesData].splice(0, 3).map(course => (
                      <PopularCourses
                        course={course}
                      />
                    )) : (
                        <div style={{ color: "#777777" }}>No Courses Available</div>
                      )
                  }

                </div>

                <hr className="w-100 clearfix d-md-none" />

                <div className="col-md-6" style={{ lineHeight: "15px" }}>
                  <div className={`row ${i18n.dir()}`}>
                    <div className="col-md-6">
                      <h6
                        className="text-uppercase mb-4"
                        style={{
                          fontSize: "14px",
                          color: "#777777",
                          fontWeight: "700",
                        }}
                      >
                        {t("content:text.Quick Links", "Quick Links")}
                      </h6>
                      <hr
                        style={{
                          height: "1px",
                          border: "none",
                          backgroundColor: "#3A3A3A",
                          color: "#777777",
                        }}
                      />
                      <ul
                        className="footer-li"
                        style={{ paddingLeft: "20px", color: "#777777" }}
                      >
                        <li>
                          <a
                            href="/courses"
                            style={{
                              fontSize: "15px",
                              lineHeight: "25px",
                              color: "#777777",
                            }}
                          >
                            {t("content:text.All Courses", "All Courses")}
                          </a>
                        </li>
                        <li>
                          <a
                            href="/"
                            style={{
                              color: "#777777",
                              fontSize: "15px",
                              lineHeight: "25px",
                            }}
                          >
                            {t("content:text.Privacy Policy", "Privacy Policy")}
                          </a>
                        </li>
                        <li>
                          <a
                            href="/"
                            style={{
                              color: "#777777",
                              fontSize: "15px",
                              lineHeight: "25px",
                            }}
                          >
                            {t("content:text.Terms of Use", "Terms of Use")}
                          </a>
                        </li>
                        <li>
                          <a
                            href="/aboutus"
                            style={{
                              color: "#777777",
                              fontSize: "15px",
                              lineHeight: "25px",
                            }}
                          >
                            {t("content:text.About Us", "About Us")}
                          </a>
                        </li>
                        <li>
                          <a
                            href="/contact"
                            style={{
                              color: "#777777",
                              fontSize: "15px",
                              lineHeight: "25px",
                            }}
                          >
                            {t("content:text.Contact Us", "Contact Us")}
                          </a>
                        </li>
                      </ul>
                      <br />
                    </div>
                    <div className="col-md-6">
                      <h6
                        className="text-uppercase mb-4"
                        style={{
                          fontSize: "14px",
                          color: "#777777",
                          fontWeight: "700",
                        }}
                      >
                        {t("content:text.Contact Us", "Contact Us")}
                      </h6>
                      <hr
                        style={{
                          height: "1px",
                          border: "none",
                          backgroundColor: "#3A3A3A",
                          color: "#3A3A3A",
                        }}
                      />
                      <p
                        style={{
                          color: "#777777",
                          fontSize: "12px",
                          lineHeight: "21px",
                        }}
                      >
                        {t(
                          "content:text.Contact us Desc",
                          "If you want to contact us about any issue our support available to help you 8am-7pm Monday to saturday."
                        )}
                      </p>
                      <p>
                        <img
                          src="/icon13.png"
                          style={
                            i18n.dir() === "ltr"
                              ? {
                                height: "15px",
                                marginRight: "5px",
                              }
                              : {
                                height: "15px",
                                marginLeft: "5px",
                                float: "right",
                              }
                          }
                          alt="..."
                        />
                        <a
                          href="/"
                          style={{
                            color: "#777777",
                            fontSize: "12px",
                            lineHeight: "21px",
                          }}
                        >
                          {t(
                            "content:text.Jeddah Address",
                            "Address: Jeddah, Saudi Arabia"
                          )}
                        </a>
                      </p>

                      <p>
                        <img
                          src="/icon11.png"
                          style={
                            i18n.dir() === "ltr"
                              ? {
                                height: "15px",
                                marginRight: "5px",
                              }
                              : {
                                height: "15px",
                                marginLeft: "5px",
                                float: "right",
                              }
                          }
                          alt="..."
                        />

                        <a
                          href="tel:+966 500 000"
                          style={{
                            color: "#777777",
                            fontSize: "12px",
                            lineHeight: "21px",
                          }}
                        >
                          {t("content:text.Nexus Call", "Call: + 966 96 966 9660")}
                        </a>
                      </p>

                      <p>
                        <img
                          src="/icon12.png"
                          style={
                            i18n.dir() === "ltr"
                              ? {
                                height: "15px",
                                marginRight: "5px",
                              }
                              : {
                                height: "15px",
                                marginLeft: "5px",
                                float: "right",
                              }
                          }
                          alt="..."
                        />
                        <a
                          href="mailto:info@nexusacademy.com"
                          style={{
                            color: "#777777",
                            fontSize: "12px",
                            lineHeight: "21px",
                          }}
                        >
                          {t("content:text.Email", "Email: info@nexusacademy.aero")}
                        </a>
                      </p>
                    </div>
                  </div>

                  <br />
                </div>
              </div>

              <hr className="w-100 clearfix d-md-none" />
              <br />
              <br />

              <br />
              <hr
                style={{
                  height: "1px",
                  border: "none",
                  backgroundColor: "#3A3A3A",
                  color: "#3A3A3A",
                }}
              />
              <br />

              <div className="row">
                <div className="col-12 col-md-7 col-8">
                  <p
                    className="text-center text-md-left"
                    style={{
                      color: "#777777",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {t(
                      "content:text.All rights desc",
                      "Maher | © 2020 Artal Group, All rights reserved"
                    )}
                  </p>
                </div>
                <div className="col-12 col-md-5 col-4">
                  <div className="footer-social-media-links">
                    <div>
                      <a href="/" className="footer-icn">
                        <i className="fab fa-facebook-square"></i>
                      </a>
                      <a href="/" className="footer-icn">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="/" className="footer-icn">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="/" className="footer-icn">
                        <i className="fab fa-linkedin-square"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
          </footer>
        </div>
      </div>
    </div>
  );
}
export default Footer;
