import React from 'react'
import NavContact from './NavContact'
import NavEmail from './NavEmail'
import LoginPopover from './LoginPopover'
import LoginRegisterButtons from './LoginRegisterButtons'
import AccountsSection from './AccountsSection'
import LanguageSection from './LanguageSection'
import InstituteInstructorButtons from './InstituteInstructorButtons'
import MobilePopoverButton from './MobilePopoverButton'

const ContactsSection = () => (
  <>
    <NavContact />
    <NavEmail />
  </>
)

export default function Ribbon({ currentUser }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark navbar-inverse nav-pad">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <LoginPopover />
        <div className="top-ribbon-desktop-buttons" style={{ height: '100%' }}>
          {currentUser ? <ContactsSection /> : <InstituteInstructorButtons />}
        </div>
        <div className="top-ribbon-desktop-buttons">
          <LanguageSection />
          <div className="logreg">
            <button
              type="button"
              style={{
                backgroundColor: '#1EABBC',
                color: '#858585',
                fontSize: '12px',
                padding: '3px 12px',
                border: 'none'
              }}
            >
              {currentUser ? <AccountsSection currentUser={currentUser}/> : <LoginRegisterButtons />}
            </button>
          </div>
        </div>
        <div className="top-ribbon-mobile-buttons">
          {/* <div className="logreg">
            <button
              type="button"
              style={{
                backgroundColor: '#FFC002',
                color: '#858585',
                fontSize: '12px',
                padding: '3px 12px',
                border: 'none'
              }}
            >
              {currentUser ? <AccountsSection /> : <LoginRegisterButtons />}
            </button>
          </div> */}
          <div>
            {!currentUser && <MobilePopoverButton currentUser={currentUser} />}
          </div>
          <div>
            <LanguageSection />
          </div>
        </div>
      </div>
    </nav>
  )
}
