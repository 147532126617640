import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

export default function NavEmail() {
  const { t } = useTranslation(['translation_lang', 'content'])
  return (
    <button
      className="navbutton"
      style={{
        borderRight: '1px solid #858585'
      }}
    >
      <a href="mailto:info@maher.com">
        <span
          className="text nav-pad-1"
          style={{
            direction: i18n.dir()
          }}
        >
          {t('content:text.email', 'Email')} :{' '}
          <span
            style={{
              color: '#777777',
              paddingLeft: '8px',
              fontWeight: '700',
              letterSpacing: '.5px'
            }}
          >
            info@maher.com
          </span>
        </span>
      </a>
      <span className="icon">
        <img
          src="./email-icon.png"
          alt="..."
          style={{
            width: '25px',
            borderRadius: '2px'
          }} />
      </span>
    </button>
  )
}
