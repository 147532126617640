import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

export const CartContext = React.createContext();

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
const DEFAULT_CART_VALUES = { currency: 'SAR', amount: 0, courses: [] }

export const CartProvider = (props) => {
  const [cart, setCart] = useState(DEFAULT_CART_VALUES);

  useEffect(() => {
    let localCart = window.localStorage.getItem("cart");
    if (localCart) setCart(JSON.parse(localCart));
    else setCart(DEFAULT_CART_VALUES);
  }, []);

  useEffect(() => {
    window.localStorage.setItem("cart", JSON.stringify(cart));
  });

  return (
    <CartContext.Provider
      value={{
        data: cart,
        addToCart: ({
          name,
          name_arb,
          price,
          _id: course_id,
          instructor: instructor_id,
          institute: institute_id,
        }) => {
          const course = {
            name,
            name_arb,
            price,
            course_id,
            instructor_id,
            institute_id,
          };

          const tempCourses = [...cart.courses].find((item) => item.course_id === course.course_id);
          console.log(tempCourses);
          if (!tempCourses) {
            setCart((currentCart) => ({
              ...currentCart,
              amount: currentCart.amount + price,
              courses: [
                ...currentCart.courses,
                course
              ]
            }));
            Toast.fire({
              icon: "success",
              title: "Course added to Cart",
            });
          } else {
            Toast.fire({
              icon: "warning",
              title: "Course already added",
            });
          }
        },
        removeFromCart: (course) => {
          let courses = [...cart.courses];
          courses = courses.filter((item) => item.course_id !== course.course_id);
          setCart((currentCart) => ({
            ...currentCart,
            amount: (currentCart.amount - course.price) || 0,
            courses
          }));
        },
        clearCart: () => {
          window.localStorage.setItem("cart", null);
          setCart(DEFAULT_CART_VALUES);
        },
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};
