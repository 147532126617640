import styled from 'styled-components'

const FormHeader = styled.div`
  padding-top: 15px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 13px;
  @media (max-width: 1199px) and (min-width: 768px) {
    padding-left: 26px;
    padding-right: 26px;
  }
  @media (min-width: 1200px) {
    padding-left: 46px;
    padding-right: 46px;
  }
`
export default FormHeader