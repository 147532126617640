
const BASE_URL = "https://study-lms-api.herokuapp.com/api";
const BASE_HEADERS = {
  headers: {
    "Content-Type": "application/json",
  },
  method: "GET",
};
export const API = {
  allInstitute: BASE_URL + "/institute/all",
  checkEmail: BASE_URL + "/auth/check-email",
  categories: BASE_URL + "/category",
  allPublicCourses: BASE_URL + "/course/all/public",
  allSliders: BASE_URL + "/slider/all",
  allPartners: BASE_URL + "/partner/all",
  checkout: BASE_URL + "/checkout",
  searchCourses: BASE_URL + "/course/search",
  getInstructor: BASE_URL + "/user/instructor/",
  getCourse: BASE_URL + "/course/",
  login: BASE_URL + "/auth/login",
  signup: BASE_URL + "/auth/signup",
  forgetPassword: BASE_URL + "/auth/forget-password",
  changePassword: BASE_URL + "/auth/change-password",
  userProfile: BASE_URL + "/auth/my-profile",
  updateProfile: BASE_URL + "/auth/update-profile",
  review: BASE_URL + "/course/review/",
  myCourses: BASE_URL + "/course/my/courses",
  popularCourses: BASE_URL + "/course/popular",
  popularInstitutes: BASE_URL + "/institute/popular",
  popularInstructors: BASE_URL + "/user/instructor/popular",
  paymentResult: BASE_URL + "/result"
};

export const fetchAPI = (url, options = {}) => {
  const { token, ...otherProps } = options
  const authorization = token ? { Authorization: `Bearer ${token}` } : {}
  const headers = {
    ...BASE_HEADERS.headers,
    ...authorization
  }
  // console.log({ headers, otherProps })

  return fetch(url, {
    ...BASE_HEADERS,
    headers,
    ...otherProps,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
      throw err
    });
};


