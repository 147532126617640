import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const linkStyle = `
  font-size: 13px;
  font-weight: 700;
  color: #1EABBC;
  cursor: pointer;
`

const CustomLink = styled(Link)`
  ${linkStyle}
`

const AnchorLink = styled.a`
  ${linkStyle}
`


export default function FormLink(props) {
  if (props.href) return <AnchorLink {...props} />

  return <CustomLink {...props} />
}
