import React, { useContext } from "react";
import styled from "styled-components";
import { FormHeader, FormLink } from "./FormControl";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../Context/ApiContext";

const Text = styled.p`
  margin: 0 4px;
  font-size: 13px;
`;
const BreadcrumbLink = styled(FormLink)`
  margin-right: 4px;
`;
const CustomFormHeader = styled(FormHeader)`
  text-transform: capitalize;
  flex-direction: ${({ language }) =>
    language === "ar" ? "row-reverse" : "row"};
`;

const TextCrumb = ({ text }) => (
  <>
    <Text>/</Text>
    <Text>{text}</Text>
  </>
);

export default function Breadcrumbs({ leafText = "", levelTwoText = "" }) {
  /* const { pathname } = window.location */
  /* const routes = pathname.split('/').map() */
  const { t } = useTranslation(["translation_lang", "content"]);
  const { language } = useContext(ApiContext);

  return (
    <CustomFormHeader language={language}>
      <BreadcrumbLink to="/">{t("content:text.home", "Home")}</BreadcrumbLink>
      <TextCrumb text={t("content:text." + leafText, leafText)} />
    </CustomFormHeader>
  );
}
