/**
 * !Desc: This module is to provide flex direction for the Arabic language
 * *if the i18n dirction is rtl then we would reverse the row
 * * impor this module wherever you need to apply row reverse
 * * row reverse is the flex direction property.
 *
 *
 * *Developed by : Saranraj Santhanam
 * *Date : July 27th 2020
 *
 */

import i18n from "../i18n";

const langDir = {
  fDir: (i18n.dir() === "rtl" ? true : false) ? "row" : "row-reverse",
};
console.log(langDir.fDir);

export default langDir;
