export default function validateLogin(values) {
    let errors = {
        email: "",
        password: ""
    };
    let cond = true;
    if (!values.email) {
        errors.email = 'Email address is required';
        cond = false;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
        cond = false;
    }
    if (!values.password) {
        errors.password = 'Password is required';
        cond = false;
    } else if (values.password.length < 8) {
        errors.password = 'Password must be 8 or more characters';
        cond = false;
    }
    return { errors, cond };
};
