import React from 'react'
import { useTranslation } from 'react-i18next';
export default function LoginRegisterButtons() {
  const { t } = useTranslation(["translation_lang", "content"]);
  return (
    <>
      <a className="anchor1" data-toggle="modal" data-target="#login" href="#/">
        {t("content:text.Login", "Login")}
      </a>{' '}
      |{' '}
      <a
        className="anchor1"
        data-toggle="modal"
        data-target="#register"
        href="#/"
      >
        {t("content:text.Register", "Register")}
      </a>
    </>
  )
}
