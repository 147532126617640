import React from "react";
import styled from "styled-components";

const FormLabelStyle = styled.div`
  align-self: center;
  margin-bottom: 21px;
`;

export default function FormLabel({ text, ...otherProps }) {
  return <FormLabelStyle {...otherProps}>{text}</FormLabelStyle>;
}
