import { useState, useEffect } from "react";
import { fetchAPI, API } from "../api";

export default function useProfile(token) {
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchUserProfile = () => {
    if(!token) return
    setLoading(true);
    fetchAPI(API.userProfile, { token })
      .then((res) => {
        setLoading(false);
        if (res.status === "Success") {
          setUserProfile(res.data);
        } else {
          setError(res);
        }
      })
      .catch(setError);
  };
  useEffect(fetchUserProfile, [token]);
  return {
    userProfile,
    loading,
    error,
  };
}
