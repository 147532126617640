import React, { useContext } from 'react'
import Error from './FormError'
import styled from 'styled-components'
import { ApiContext } from '../../Context/ApiContext'

const FormInputStyle = styled.div`
  align-self: center;
  margin-bottom: 21px;
`;

const InputWrapper = styled.input`
  width: 100%;
  padding: 7px 12px;
  height: 42px;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
  display: block;
  width: 100%;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type=number] {
    -moz-appearance: textfield;
  }
  &:disabled {
    cursor: not-allowed;
  }
`

const FormInput = ({ error, parentProps = {}, ...props }) => {
  const { language } = useContext(ApiContext)
  const direction = language === "ar" ? { direction: "rtl" } : { direction: "ltr" }
  return (
    <FormInputStyle {...parentProps}>
      <InputWrapper type="text" style={direction} className="focusedInput" {...props} />
      <Error error={error} />
    </FormInputStyle>
  )
}

export default FormInput
