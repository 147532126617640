import React from 'react'
import styled, { css } from 'styled-components'
import './Modal.css'
import { ApiContext } from '../Context/ApiContext'
import { useContext } from 'react'
import HandleLoginForm from '../helpers/HandleLoginForm'
import { MiniLoader } from '../Components/MiniLoader'
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import {
  FormError,
  LoginRegisterText,
  FormButton,
  RememberMe,
  FormLink,
  FormInput
} from './FormControl'

const CustomFormLink = styled(FormLink)`
  float: right;
  padding-left: 19px;
  padding-top: 10px;
`

const RememberMeMobileWrapper = styled.div`
  display: none;
  ${({ fullPage }) =>
    fullPage &&
    css`
      @media (max-width: 1199px) and (min-width: 768px) {
        display: 'flex';
      }
    `}

  @media (max-width: 450px) {
    display: flex;
  }
`

const RememberMeMobile = ({ check, handleCheckBox, isFullPage }) => (
  <RememberMeMobileWrapper>
    <RememberMe check={check} handleCheckBox={handleCheckBox} smShow={true} />
  </RememberMeMobileWrapper>
)

export default function Login({
  isUserLogin = true,
  type = "",
  registerHandler,
  isFullPage
}) {
  const { errorMessage, status, language } = useContext(ApiContext)

  const { t } = useTranslation(["translation_lang", "content"]);

  const textAlign = language === "ar" ? { textAlign: "right" } : { textAlign: "left" }
  const flexDirection = language === "ar" ? { flexDirection: "row-reverse" } : { flexDirection: "row" }

  const {
    values,
    check,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleCheckBox
  } = HandleLoginForm()

  if (isSubmitting || status)
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <MiniLoader />
      </div>
    )
  return (
    <>
      <form style={textAlign} onSubmit={handleSubmit} noValidate autoComplete="on">
        <h2 id="exampleModalLabel">{t("content:text.Login Form", "Login Form")}</h2>
        <FormInput
          id="email-login"
          type="email"
          value={values.email || ''}
          placeholder={t("content:text.Email Address", "Email Address")}
          name="email"
          aria-describedby="emailHelp"
          onChange={handleChange}
          required
          error={errors.email && t("content:text." + errors.email, errors.email)}
        // error={errors.email}
        />
        <FormInput
          id="password-login"
          type="password"
          name="password"
          placeholder={t("content:text.Password", "Password")}
          onChange={handleChange}
          value={values.password || ''}
          required
          error={errors.password && t("content:text." + errors.password, errors.password)}
        />
        <div style={{ ...flexDirection, ... { display: 'flex', justifyContent: "space-around" } }}>
          <FormButton type="submit">{t("content:text.Login", "Login")}</FormButton>
          <RememberMe
            check={check}
            handleCheckBox={handleCheckBox}
            isFullPage={isFullPage}
          />
          <CustomFormLink href="/forgotpassword">
            {t("content:text.Lost your Password", "Lost your Password")}
          </CustomFormLink>
        </div>
        <RememberMeMobile
          check={check}
          handleCheckBox={handleCheckBox}
          isFullPage={isFullPage}
        />
        <LoginRegisterText
          type={type}
          registerHandler={registerHandler}
          isUserLogin={isUserLogin}
        />
        {!status && <FormError error={errorMessage} />}
      </form>
    </>
  )
}
