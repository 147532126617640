import React, { useContext } from 'react'
import FormLink from './FormLink'
import { useTranslation } from "react-i18next";
import { ApiContext } from '../../Context/ApiContext';

const LoginRegisterText = ({ registerHandler, isUserLogin, text, linkText, type }) => {
  const { t } = useTranslation(["translation_lang", "content"]);
  const { language } = useContext(ApiContext)

  const flexDirection = language === "ar" ? { flexDirection: "row-reverse" } : { flexDirection: "row" }

  if (isUserLogin)
    return null
  return (
    <div
      style={{
        ...flexDirection, ...{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      }}
    >
      <div
        style={{
          fontSize: '14px',
          marginTop: '15px'
        }}
      >
        <label
          style={{
            fontWeight: '100',
            marginRight: '8px',
          }}
        >
          {text || t("content:text.Don't have an account?", "Don't have an account?")}
        </label>
        <FormLink
          onClick={registerHandler}
        >
          {linkText || t(`content:text.Create an ${type} Account`, `Create an ${type} Account`)}
        </FormLink>
      </div>
    </div>
  )
}

export default LoginRegisterText