import { useState, useEffect } from 'react';
import { ApiContext } from '../Context/ApiContext';
import { useContext } from 'react';
import validateLogin from '../helpers/validateLogin'

const HandleLoginForm = () => {

    const [values, setValues] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [check, setCheck] = useState(false)

    const { login } = useContext(ApiContext)


    useEffect(() => {
        const rememberMe = localStorage.getItem("rememberMe");
        const user = rememberMe ? localStorage.getItem("email") : "";
        setValues(values => ({ ...values, email: user }))
        rememberMe ? setCheck(true) : setCheck(false)
    }, [])

    function handleCheckBox() {
        setCheck(!check)
    }

    function setLocalData() {
        if (check === true) return (
            localStorage.setItem("email", values.email),
            localStorage.setItem("rememberMe", check)
        )
        else return (
            localStorage.setItem("email", ""),
            localStorage.setItem("rememberMe", check)
        )
    }

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        console.log(values)
        let { errors, cond } = validateLogin(values)
        setErrors(errors);
        if (cond) {
            setIsSubmitting(true)
            login(values).then(() => setIsSubmitting(false))
            setLocalData()
        }
    };

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    };

    return {
        handleChange,
        handleSubmit,
        handleCheckBox,
        isSubmitting,
        check,
        values,
        errors,
    }
};

export default HandleLoginForm;