import React from "react";
import Routes from "./router";
import { ApiProvider } from "./Context/ApiContext";
import { ThemeProvider } from "./Context/ThemeContext";

export default () => (
    <ThemeProvider>
        <ApiProvider>
            <Routes />
        </ApiProvider>
    </ThemeProvider>
);
