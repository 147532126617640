
import React from "react";
import "./Navbar/index.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import langDir from "../Components/LangDir";
import { useHistory, Link } from "react-router-dom";

function NavButton({ buttonText, linkPath }) {
    return (
        <div className="logreg">
            <button
                type="button"
                style={{
                    backgroundColor: '#1EABBC',
                    color: '#858585',
                    fontSize: '12px',
                    padding: '3px 12px',
                    border: 'none'
                }}
            >
                <Link to={linkPath} className="anchor1" >
                    {buttonText}
                </Link>
            </button>
        </div>
    )
}


function Navbar2() {
    let history = useHistory();
    const { t } = useTranslation(["translation_lang", "content"]);

    return (
        <div
            style={{ backgroundColor: "#222222", flexDirection: `${langDir.fDir}` }}
        >
            <div className="container cont-pad ">
                <nav className="navbar navbar-expand-lg navbar-dark navbar-inverse nav-pad">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div className="top-nav-pad" style={{ height: "100%" }}>
                            <button className="navbutton">
                                <a href="tel:+966 500 000">
                                    <span className="text nav-pad-1">
                                        Call :{" "}
                                        <span
                                            style={{
                                                color: "#777777",
                                                paddingLeft: "8px",
                                                fontWeight: "700",
                                                letterSpacing: ".5px",
                                            }}
                                        >
                                            +(966) 500 000
                    </span>
                                    </span>
                                </a>
                                <span className="icon">
                                    <img
                                        src="./phone-icon.png"
                                        alt="..."
                                        style={{
                                            maxWidth: "25px",
                                            borderRadius: "2px",
                                        }}
                                    />
                                </span>
                            </button>
                            <button
                                className="navbutton"
                                style={{
                                    borderRight: "1px solid #858585",
                                }}
                            >
                                <a href="mailto:info@maher.com">
                                    <span
                                        className="text nav-pad-1"
                                        style={{
                                            direction: i18n.dir(),
                                        }}
                                    >
                                        {t("welcome:content.email", "Email")} :{" "}
                                        <span
                                            style={{
                                                color: "#777777",
                                                paddingLeft: "8px",
                                                fontWeight: "700",
                                                letterSpacing: ".5px",
                                            }}
                                        >
                                            info@maher.com
                    </span>
                                    </span>
                                </a>
                                <span className="icon">
                                    <img
                                        src="./email-icon.png"
                                        alt="..."
                                        style={{
                                            width: "25px",
                                            borderRadius: "2px",
                                        }}
                                    />
                                </span>
                            </button>
                        </div>
                        <div>
                            <button
                                style={{
                                    backgroundColor: "#1EABBC",
                                    color: "#858585",
                                    fontSize: "12px",
                                    padding: "3px 12px",
                                    border: "none",
                                }}
                                onClick={() => history.push("/")}
                            >
                                {t("content:text.Back", "Back")}
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Navbar2;
