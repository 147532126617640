import React, { useContext } from 'react'
import './Modal.css'
import HandleRegisterForm from '../helpers/HandleRegisterForm'
import { ApiContext } from '../Context/ApiContext'
import { MiniLoader } from '../Components/MiniLoader'
import { FormButton, FormError, FormInput } from './FormControl'
import { useTranslation } from "react-i18next";

export default function Register() {
  const {
    errors,
    isSubmitting,
    handleConfirmPassword,
    handleChange,
    handleSubmit
  } = HandleRegisterForm()

  const { errorMessage, status, language } = useContext(ApiContext)
  const { t } = useTranslation(["translation_lang", "content"]);

  const textAlign = language === "ar" ? { textAlign: "right" } : { textAlign: "left" }

  if (isSubmitting || status)
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <MiniLoader />
      </div>
    )
  return (
    <>
      <form style={textAlign} onSubmit={handleSubmit} noValidate autoComplete="on">
        <h2 id="exampleModalLabel">{t("content:text.Register Form", "Register Form")}</h2>
        <FormInput
          id="fname"
          type="text"
          name="first_name"
          placeholder={t("content:text.First Name", "First Name")}
          aria-describedby="emailHelp"
          onChange={handleChange}
          error={errors.first_name && t("content:text." + errors.first_name, errors.first_name)}
        />
        <FormInput
          id="lname"
          name="last_name"
          placeholder={t("content:text.Last Name", "Last Name")}
          onChange={handleChange}
          required
          error={errors.last_name && t("content:text." + errors.last_name, errors.last_name)}
        />
        <FormInput
          id="phone"
          type="number"
          name="phone"
          title="Enter 10 Digit Mobile Number"
          placeholder={t("content:text.Phone", "Phone")}
          onChange={handleChange}
          required
          error={errors.phone && t("content:text." + errors.phone, errors.phone)}
        />
        <FormInput
          id="email"
          type="email"
          name="email"
          placeholder={t("content:text.Email Address", "Email Address")}
          aria-describedby="emailHelp"
          onChange={handleChange}
          required
          error={errors.email && t("content:text." + errors.email, errors.email)}
        />
        <FormInput
          type="password"
          placeholder={t("content:text.Password", "Password")}
          id="password"
          name="password"
          onChange={handleChange}
          required
          error={errors.password && t("content:text." + errors.password, errors.password)}
        />
        <FormInput
          type="password"
          placeholder={t("content:text.Confirm Password", "Confirm Password")}
          id="cpassword"
          onChange={handleConfirmPassword}
          required
          error={errors.confirmPassword && t("content:text." + errors.confirmPassword, errors.confirmPassword)}
        />
        <FormButton
          type="submit"
          className="btn btn-theme btn-warning fw-bold font-lato text-uppercase"
        >
          {t("content:text.Register", "Register")}
        </FormButton>
        {!status && <FormError error={errorMessage} />}
      </form>
    </>
  )
}
