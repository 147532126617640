import styled from 'styled-components'

const LoginContainer = styled.div`
  margin-top: ${({ marginTop }) => (marginTop? marginTop : '50px')};
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: 768px){
    width: ${({ width }) => (width ? width : '55%')};
  }
`;

export default LoginContainer

