import React from 'react';

export default function CheckboxIcon({ isChecked, onClick }) {
  const checkedClass = isChecked ? 'fa fa-check-square' : 'fa fa-square-o';
  return (
    <i
      class={checkedClass}
      onClick={onClick}
      style={{
        padding: 0,
        margin: 'auto'
      }}
      aria-hidden="true"
    />
  );
}
