const NAME_REGEX = /^[A-Za-z\u0600-\u06FF]{2,30}$/
export default function validateLogin(values, confirmPassword) {
    let errors = {};
    let cond = true;
    if (!values.first_name) {
        errors.first_name = 'First Name is required';
        cond = false;
    } else if (!NAME_REGEX.test(values.first_name)) {
        errors.first_name = 'Should be more than two alphabets(A-Za-z)';
        cond = false;
    }
    if (!values.last_name) {
        errors.last_name = 'Last Name is required';
        cond = false;
    } else if (!NAME_REGEX.test(values.last_name)) {
        errors.last_name = 'Should be more than two alphabets(A-Za-z)';
        cond = false;
    }
    if (!values.phone) {
        errors.phone = 'Phone Number is required';
        cond = false;
    } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(values.phone)) {
        errors.phone = 'Please enter a valid phone number';
        cond = false;
    }
    if (!values.email) {
        errors.email = 'Email address is required';
        cond = false;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
        cond = false;
    }
    if (!values.password) {
        errors.password = 'Password is required';
        cond = false;
    } else if (values.password.length < 8) {
        errors.password = 'Password must be 8 or more characters';
        cond = false;
    }
    if (!confirmPassword) {
        errors.confirmPassword = 'Confirm Password is required';
        cond = false;
    } else if (values.password !== confirmPassword) {
        errors.confirmPassword = 'Password does not match';
        cond = false;
    }
    return { errors, cond };
};
