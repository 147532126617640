import React, { useContext } from 'react'
import i18n from '../../../i18n'
import { ApiContext } from '../../../Context/ApiContext'
export default function LanguageSection() {
  const { setLanguage } = useContext(ApiContext)
  return (
    <>
      <button
        className="anchor lang-button en-lang-ar"
        onClick={() => {
          i18n.changeLanguage('en')
          localStorage.setItem('Language', 'en')
          setLanguage("en")
        }}
      >
        English
      </button>{' '}
      <span className="sep">|</span>
      <button
        className="anchor lang-button"
        style={{ paddingRight: '5px' }}
        onClick={() => {
          i18n.changeLanguage('ar')
          localStorage.setItem('Language', 'ar')
          setLanguage("ar")
        }}
      >
        Arabic
      </button>
    </>
  )
}
