import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { CartProvider } from "../Context/CartContext";
import "./Layout.css"

export default function Layout(props) {
  const [showScroll, setShowScroll] = useState(false);
  const { showNav = true, onlyMobileNav = false } = props
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div>
      <CartProvider>
        <Navbar showNavigation={showNav} onlyMobileNav={onlyMobileNav}/>
        <div>{props.children}</div>
        <a href="#top">
          <span
            id="back-top"
            onClick={scrollTop}
            style={{ height: "40", display: showScroll ? "flex" : "none" }}
            className="text-center fa fa-caret-up scrollTop"
          ></span>
        </a>
        <Footer />
      </CartProvider>
    </div>
  );
}
