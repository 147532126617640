import styled from "styled-components";

const FormMultiInput = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 49%;
  }

  @media (max-width: 768px) {
    ${({ responsive }) =>
      responsive &&
      `flex-direction: column`
      }
  }
`;
export default FormMultiInput;
