import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function logout() {
  localStorage.removeItem("currentUser");
  window.location.replace("/");
}

export default function AccountsSection({ currentUser = {} }) {
  const { t } = useTranslation(["translation_lang", "content"]);

  const ACCOUNT_SUB_MENUS = [
    { title: t("content:text.Profile", "Profile"), path: "/myaccount/profile", role: "" },
    { title: t("content:text.courses", "Courses"), path: "/myaccount/courses", role: "" },
    { title: t("content:text.Attendance", "Attendance"), path: "/myaccount/attendance", role: "instructor" },
    { title: t("content:text.Certificates", "Certificates"), path: "/myaccount/certificates", role: "user" },
    { title: t("content:text.Orders", "Orders"), path: "/myaccount/orders", role: "user" }
  ];
  const { role: currentRole } = currentUser.user || {}
  return (
    <div
      className="dropdown"
      style={{ position: "inherit", right: "0", left: "auto" }}
    >
      <div
        className="anchor1"
        id="navbarDropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {t("content:text.My Account", "My Account")}
      </div>
      <div
        className="dropdown-menu"
        style={{
          zIndex: "99999",
          border: "none",
          backgroundColor: "#ECECEC",
          borderTop: "3px solid #1EABBA",
          right: "0",
          left: "auto",
          padding: "0",
        }}
        aria-labelledby="navbarDropdown"
      >
        {ACCOUNT_SUB_MENUS.map(({ role, path, title }) => {
          const isVisible = role && currentRole.length ? currentRole.includes(role) : true
          if (!isVisible) return null
          return (
            <Link to={path}>
              <div className="dropdown-item">{title}</div>
            </Link>
          )
        })}
        <div className="dropdown-item" onClick={() => logout()}>
          {t("content:text.Logout", "Logout")}
        </div>
      </div>
    </div>
  );
}
