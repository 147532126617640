import React, { useState } from 'react'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import LoginPopover from './LoginPopover'
import { Link } from 'react-router-dom'

const Button = ({ text, anchorProps = {}, type = null }) => (
  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    <button
      type="button"
      style={{
        width: '80px',
        margin: '4px 0',
        backgroundColor: 'rgb(255, 192, 2)',
        color: 'rgb(133, 133, 133)',
        fontSize: '12px',
        padding: '3px 12px',
        border: 'none'
      }}
    >
      <Link className='anchor1' {...anchorProps} to={{
        defaultForm: type,
        pathname: anchorProps.href || '',
      }} >
        {text}
      </Link>
    </button>
  </div>
)

function ButtonLayout({
  isPopoverOpen,
  setIsPopoverOpen,
  titleText,
  studentProps,
  type
}) {
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        width: '120px',
        height: '120px'
      }}
      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {' '}
        <div
          style={{
            width: '80px',
            fontWeight: 'bold',
            marginTop: '4px',
            fontSize: '12px'
          }}
        >
          {titleText}
        </div>
      </div>
      <Button anchorProps={studentProps} text="Student" />
      <Button text="Instructor" anchorProps={{ href: '/instructor' }} type={type} />
      <Button text="Institute" anchorProps={{ href: '/institute' }} type={type}/>
    </div>
  )
}
export default function MobilePopoverButton() {
  const [isLogin, setIsLogin] = useState(false)
  const [isRegister, setIsRegister] = useState(false)
  const toggleLoginPopover = () => {
    setIsLogin(!isLogin)
    setIsRegister(false)
  }
  const toggleRegisterPopover = () => {
    setIsRegister(!isRegister)
    setIsLogin(false)
  }
  const resetPopover = () => {
    setIsLogin(false)
    setIsRegister(false)
  }
  return (
    <Popover
      isOpen={isLogin || isRegister}
      position={'bottom'}
      padding={10}
      containerStyle={{ zIndex: '1061', top: '40px' }}
      onClickOutside={resetPopover}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'black'}
          arrowSize={10}
          arrowStyle={{ left: isLogin ? '25px' : '60px' }}
        >
          {isLogin && (
            <ButtonLayout
              studentProps={{ 'data-toggle': 'modal', 'data-target': '#login' }}
              type="login"
              isPopoverOpen={isLogin}
              setIsPopoverOpen={() => setIsLogin(false)}
              titleText="Login As"
            />
          )}
          {isRegister && (
            <ButtonLayout
              studentProps={{
                'data-toggle': 'modal',
                'data-target': '#register'
              }}
              type="register"
              isPopoverOpen={isRegister}
              setIsPopoverOpen={() => setIsRegister(false)}
              titleText="Register As"
            />
          )}
        </ArrowContainer>
      )}
    >
      <div>
        <button
          type="button"
          style={{
            backgroundColor: '#1EABBC',
            color: '#858585',
            fontSize: '12px',
            padding: '3px 12px',
            border: 'none'
          }}
        >
          <a className="anchor1" onClick={toggleLoginPopover}>
            Login{' '}
          </a>
          <a className="anchor1" onClick={toggleRegisterPopover}>
            | Register{' '}
          </a>
        </button>
        <LoginPopover />
      </div>
    </Popover>
  )
}
