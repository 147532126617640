import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

function NavButton({ buttonText, linkPath }) {
  return (
    <div className="logreg">
      <button
        type="button"
        style={{
          backgroundColor: '#1EABBC',
          color: '#858585',
          fontSize: '12px',
          padding: '3px 12px',
          border: 'none'
        }}
      >
        <Link to={linkPath} className="anchor1">
          {buttonText}
        </Link>
      </button>
    </div>
  )
}

export default function InstituteInstructorButtons() {
  const { t } = useTranslation(["translation_lang", "content"]);
  return (
    <>
      <NavButton
        buttonText={t("content:text.Instructor Login", "Instructor Login")}
        linkPath="/instructor"
      />
      <NavButton buttonText={t("content:text.Institute Login", "Institute Login")} linkPath="/institute" />
    </>
  )
}
