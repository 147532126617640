import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink, Redirect } from "react-router-dom";
import CoverImage from "../Views/CoverImage";
import "../Views/Modal.css";
import { FormContainer } from "../Views/FormControl";
import Breadcrumbs from "../Views/Breadcrumbs";
import { AccountContext, AccountProvider } from "../Context/AccountContext";
import { ApiContext } from "../Context/ApiContext";
import { useTranslation } from "react-i18next";


const AccountContainer = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  min-height: 75vh;
  @media (min-width: 768px) {
    width: 75%;
  }
`;

const SideMenu = styled.div`
  width: 300px;
  margin: 50px 0;
  @media (max-width: 768px) {
    display: none;
  }
  & .selected {
    &:hover {
      border-left: 5px solid #1eabbc;
    }
  }
`;

const NavLinkItem = styled.div`
  padding: 1rem 1rem;
  color: black;
`;

const activeNavStyle = {
  borderLeft: "5px solid #1EABBC",
  // backgroundColor: "#1EABBC",
  color: "#525252",
  fontWeight: "700",
};

function logout() {
  localStorage.removeItem("currentUser");
  window.location.replace("/");
}

function NavItem({ path, title, onClick, role, currentRole = [] }) {
  const isVisible = role && currentRole.length ? currentRole.includes(role) : true
  if (!isVisible) return null
  const activeStyle = path ? activeNavStyle : {};
  return (
    <NavLink
      to={path}
      className="selected"
      activeStyle={activeStyle}
      onClick={onClick}
    >
      <li className="nav-item" style={{ cursor: "pointer" }}>
        <NavLinkItem>{title}</NavLinkItem>
      </li>
    </NavLink>
  );
}

function MenuItems({ profile }) {
  const { role = [] } = profile
  const { t } = useTranslation(["translation_lang", "content"]);

  const ACCOUNT_SUB_MENUS = [
    { title: t("content:text.Profile", "Profile"), path: "/myaccount/profile", role: "" },
    { title: t("content:text.courses", "Courses"), path: "/myaccount/courses", role: "" },
    { title: t("content:text.Attendance", "Attendance"), path: "/myaccount/attendance", role: "instructor" },
    { title: t("content:text.Certificates", "Certificates"), path: "/myaccount/certificates", role: "user" },
    { title: t("content:text.Orders", "Orders"), path: "/myaccount/orders", role: "user" }
  ];

  console.log({ profile })
  return (
    <ul className="nav flex-column">
      {ACCOUNT_SUB_MENUS.map((data, key) => <NavItem {...data} key={key} currentRole={role} />)}
      <NavItem path="" title={t("content:text.Logout", "Logout")} onClick={logout} />
    </ul>
  );
}

function DesktopMenu(props) {
  const { profile } = useContext(AccountContext)

  const { language } = useContext(ApiContext)
  const textAlign = language === "ar" ? { textAlign: "left" } : { textAlign: "right" }
  return (
    <div
      className="sub5"
      style={{
        // height: "100%",
        textAlign: "right",
        background: "#e8e8e8",
        color: "black",
      }}
    >
      <ul className="nav flex-column">
        <MenuItems profile={profile} textAlign={textAlign} />
      </ul>
    </div>
  );
}

const ROUTE_PAGE_TITLE_MAP = {
  mycourses: "My Courses",
};

const getCurrentPageName = (location) => {
  const pathname = location.pathname.split("/");
  const currentPage = pathname[pathname.length - 1] || "";
  return ROUTE_PAGE_TITLE_MAP[currentPage] || currentPage;
};
export default function AccountLayout(props) {
  const { token, language } = useContext(ApiContext);
  const { t } = useTranslation(["translation_lang", "content"]);
  // if (!token) {
  //   return <Redirect to="/" />
  // }

  const flexDirection = language === "ar" ? { flexDirection: "row-reverse" } : { flexDirection: "row" }
  const textAlign = language === "ar" ? { textAlign: "right" } : { textAlign: "left" }

  const side = language === "ar" ? { borderLeft: "1px solid #c3c3c3", paddingLeft: "20px" } : { borderRight: "1px solid #c3c3c3", paddingRight: "20px" }
  return (
    <div>
      <AccountProvider token={token}>
        <CoverImage marginTop={"0px"} />
        <AccountContainer style={flexDirection}>
          <SideMenu style={side}>
            <DesktopMenu />
          </SideMenu>
          <FormContainer marginTop="0" width="100%" style={{ margin: 0 }}>
            <Breadcrumbs leafText={getCurrentPageName(window.location)} />
            {props.children}
          </FormContainer>
        </AccountContainer>
      </AccountProvider>
    </div>
  );
}
