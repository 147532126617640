import React from 'react'
import styled from 'styled-components'

const FormButtonWrapper = styled.button`
  color: white;
  border-radius: 0px;
  font-weight: bold;
  font-size: 12px;
  min-width: 100px;
  margin-right: 17px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #ffc107;
  border-color: #ffc107;
  text-transform: uppercase !important;
  color: white;
  border-radius: 0px;
  font-weight: bold;
  font-size: 12px;
  min-width: 100px;
  margin-right: 17px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`

const FormButton = (props) => (
  <FormButtonWrapper
    className="btn-theme btn-warning fw-bold font-lato text-uppercase"
    {...props}
  />
)
export default FormButton
