import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import i18n from "../i18n";
import { fetchAPI, API } from "../api";
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const SINGLE_COURSE = {
  course_name: "",
  id: "",
  image: "",
  banner: "",
  instructor: "",
  description: "",
  lectures: "",
  learn: "",
  duration: {
    count: 0,
    unit: "",
  },
  location: {
    premises: true,
    external: null,
  },
  category: "",
  requirements: "",
  sections: [],
  price: 0,
  instructor_img: "",
};

const DEFAULT_INSTRUCTOR = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  profile: {
    picture: "",
  },
  info: {
    title: {
      value: "",
    },
    biography: {
      value: "",
    },
    highlights: {
      value: "",
    },
    experience: {
      value: "",
    },
    expert: {
      value: "",
    },
  },
};

const associateCourseInstructor = (courses, instructors) => {
  return courses.map((course) => {
    let instructor = instructors.find(
      ({ data }) => data._id === course.instructor
    );
    if (instructor)
      return {
        ...course,
        instructor: instructor.data,
      };
    else
      return {
        ...course,
        instructor: { first_name: "N/A", last_name: "" },
      };
  });
};

const incorrectLoginFormToast = (role) =>
  Swal.fire({
    icon: "Failure",
    title: `Please use ${role} login form`,
  })

const loginSuccessToast = () =>
  Swal.fire({
    icon: "Success",
    title: "Registration Success",
    text: "Email sent, please check your Inbox",
    confirmButtonColor: "#1EABBC",
    width: "400px",
  }).then(() => {
    window.location.reload();
  });

const paymentSuccess = () =>
  Swal.fire({
    icon: "Success",
    title: "Your Order was successful",
    width: "400px",
  }).then(() => {
    window.location.replace("/");
  })

export const ApiContext = React.createContext();

export const ApiProvider = (props) => {
  const [language, setLanguage] = useState("en");
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [checkoutId, setCheckoutId] = useState("");
  const [status, setStatus] = useState(false);
  const [courses, setCourses] = useState([]);
  const [searchCourseData, setSearchCourseData] = useState([]);
  const [centers, setCenters] = useState([]);
  const [slider, setSlider] = useState([]);
  const [instructor, setInstructor] = useState(DEFAULT_INSTRUCTOR);
  const [popularInstructor, setPopularInstructor] = useState([]);
  const [popularInstitute, setPopularInstitute] = useState([]);
  const [popularCourse, setPopularCourse] = useState([]);

  const [partner, setPartner] = useState([]);
  const [locationData, setLocationData] = useState({ address: "" });

  const [singleCourse, setSingleCourse] = useState(SINGLE_COURSE);
  const [token, setToken] = useState(null);
  async function CheckMail(email) {
    return await fetchAPI(API.checkEmail, {
      method: "post",
      body: JSON.stringify({ email }),
    });
  }

  useEffect(() => {
    let lang = localStorage.getItem("Language");
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [language]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser"));

    setToken(user ? user.token : null);
  }, [token]);

  useEffect(
    () =>
      fetchAPI(API.popularInstitutes).then(({ status, data }) => {
        console.log(data);
        if (status === "Success") {
          setCenters(data);
        }
      }),
    []
  );

  useEffect(() => {
    fetchAPI(API.popularInstructors)
      .then(({ status, data }) => {
        if (status === "Success") {
          setPopularInstructor(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let courses = [];
    fetchAPI(API.popularCourses)
      .then(({ status, data }) => {
        if (status === "Success") {
          courses.push(...data);
          return data;
        }
      })
      .then((data) => {
        return data.map((course) =>
          fetchAPI(API.getInstructor + course.instructor)
        );
      })
      .then((arr) => Promise.all(arr))
      .then((arr) => Promise.all(arr))
      .then((res) => setPopularCourse(associateCourseInstructor(courses, res)))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetchAPI(API.popularInstitutes)
      .then((res) => {
        const { status, data } = res
        if (status === "Success") {
          setPopularInstitute(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(
    () =>
      fetchAPI(API.categories).then(({ status, data }) => {
        if (status === "Success") {
          setCategories(data.categories);
        }
      }),
    []
  );

  useEffect(() => {
    const courses = [];
    return fetchAPI(API.allPublicCourses)
      .then(({ status, data }) => {
        if (status === "Success") {
          courses.push(...data);
          return data;
        }
      })
      .then((data) => {
        return data.map(({ instructor }) =>
          fetchAPI(API.getInstructor + instructor)
        );
      })
      .then((arr) => Promise.all(arr))
      .then((res) => setCourses(associateCourseInstructor(courses, res)));
  }, []);

  useEffect(
    () =>
      fetchAPI(API.allSliders).then((res) => {
        let images = [];
        res.data.images.forEach((element) => {
          images.push(element);
        });
        setSlider(images);
      }),
    []
  );

  useEffect(
    () =>
      fetchAPI(API.allPartners).then((res) => {
        let images = [];
        res.data.images.forEach((element) => {
          images.push(element);
        });
        setPartner(images);
      }),
    []
  );
  return (
    <ApiContext.Provider
      value={{
        errorMessage: error,
        status,
        token,
        courses,
        centers,
        instructor,
        popularInstructor,
        popularCourse,
        popularInstitute,
        slider,
        language,
        setLanguage,
        categories,
        partner,
        locationData,
        singleCourse,
        checkoutId,
        getCheckoutId: async (body) => {
          console.log({ body })
          const response = await fetchAPI(API.checkout, {
            token,
            method: "POST",
            body: JSON.stringify(body)
          });

          console.log({ response });
          setCheckoutId(response.id);
        },
        searchCourseData,
        searchCourse: async (categoryId, searchText) => {
          let body = JSON.stringify({
            search: searchText,
            category: categoryId,
          });
          let result = await fetchAPI(API.searchCourses, {
            method: "post",
            body: body,
          });

          setSearchCourseData([...result.data]);
          console.log(result.data);
        },
        getInstructor: async (instructorId) => {
          let { data, status } = await fetchAPI(API.getInstructor + instructorId);
          if (status === "Success") {
            setInstructor(data);
          }
        },
        postReview: async (courseId) => {
          let { data, status } = await fetchAPI(API.review + courseId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            method: "POST",
          }).then((result) => {
            if (result.status === "Success") {
              Toast.fire({
                icon: "success",
                title: "Review Successfully added",
              })
            } else {
              Toast.fire({
                icon: "warning",
                title: "Failed add a review",
              })
            }
          })
            .catch((err) => {
              console.log(err);
            });
        },
        getSingleCourse: async (courseId) => {
          let result = await fetchAPI(API.getCourse + courseId);
          setSingleCourse(result.data);
        },
        getLocation: async locationId => {
          let { data, status } = await fetchAPI(API.getLocation + locationId);
          if (status === "Success") {
            setLocationData(data)
          }
        },
        login: async (FormData) => {
          let result = await fetchAPI(API.login, {
            method: "post",
            body: JSON.stringify(FormData),
          });
          const handleLoginSuccess = (data) => {
            localStorage.setItem("currentUser", JSON.stringify(data));
            setStatus(true);
            window.location.reload();
          }
          if (result.status === "Success") {
            const { pathname: currentPath = "" } = window.location;
            const { user } = result.data
            const isCorrectUser = currentPath.toString().includes(user.role[0])
            const isInstituteOrInstructor = (path) => ["instructor", "institute"].some(t => path.includes(t))
            if (isInstituteOrInstructor(currentPath) && !isCorrectUser) {
              incorrectLoginFormToast(user.role[0] === "user" ? "Student" : user.role[0])
            } else {
              handleLoginSuccess(result.data)
            }
          } else {
            setStatus(false);
            setError(result.message);
          }
        },
        completePayment: async (body) => {
          fetchAPI(API.paymentResult + "/" + body.id, {
            token,
          }).then(res => {
            paymentSuccess()
            return res
          })
        },
        register: (FormData) => {
          return CheckMail(FormData.email)
            .then((res) => {
              console.log(res);
              if (res.message !== "Email Found!")
                return fetchAPI(API.signup, {
                  method: "post",
                  body: JSON.stringify(FormData),
                });
              else {
                setError("Email Already Registered");
                setStatus(false);
              }
            })
            .then((result) => {
              console.log(result);
              if (result.status === "Success") {
                setStatus(true);
                loginSuccessToast();
              } else {
                setError("Registration failed");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};
