import styled from 'styled-components'
import coverImage from '../assets/cover-image.jpeg'

const CoverImage = styled.div`
  width: 100%;
  background-image: url(${coverImage});
  height: 100px;
  margin-top: ${props => props.marginTop ? props.marginTop : '47px'};

  @media (max-width: 1200px) {
    margin-top: ${props => props.marginTop ? props.marginTop : '40px'}
  }
`

export default CoverImage